var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _vm.isPrompt
        ? _c("div", { staticClass: "tips" }, [_vm._v(_vm._s(_vm.prompt))])
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.takeOutForm,
            rules: _vm.rules,
            "label-width": "80px",
          },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              disabled: _vm.disabled,
              showAudit: _vm.showAudit,
              isAddBtn: _vm.showAdd,
              isExamineBtn: true,
              id: "topOperatingButton",
            },
            on: {
              submitForm: _vm.submitForm,
              addBill: _vm.addBill,
              auditBill: _vm.auditBill,
              handleAdd: _vm.handleAdd,
              getQuit: _vm.getQuit,
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                auditOrder: _vm.auditOrder,
                noAuditOrder: _vm.noAuditOrder,
                stopOrder: _vm.stopOrder,
                deleteOrder: _vm.deleteOrder,
                disableOrder: _vm.disableOrder,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    { staticClass: "x-x marB10" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "label",
                          attrs: { label: "方案编号", prop: "billNo" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth marR15",
                            attrs: {
                              disabled: true,
                              size: "mini",
                              placeholder: "方案编号",
                            },
                            model: {
                              value: _vm.takeOutForm.billNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.takeOutForm, "billNo", $$v)
                              },
                              expression: "takeOutForm.billNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "方案名称", prop: "promoName" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: {
                              disabled: _vm.disabled,
                              size: "mini",
                              placeholder: "方案名称",
                            },
                            model: {
                              value: _vm.takeOutForm.promoName,
                              callback: function ($$v) {
                                _vm.$set(_vm.takeOutForm, "promoName", $$v)
                              },
                              expression: "takeOutForm.promoName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "有效日期", prop: "datetime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              size: "mini",
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              disabled: _vm.disabled,
                              "picker-options": _vm.forbiddenTime,
                            },
                            model: {
                              value: _vm.takeOutForm.datetime,
                              callback: function ($$v) {
                                _vm.$set(_vm.takeOutForm, "datetime", $$v)
                              },
                              expression: "takeOutForm.datetime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "billRemark" } },
                        [
                          _c("el-input", {
                            staticClass: "inputRemark",
                            attrs: {
                              disabled: _vm.disabled,
                              size: "mini",
                              type: "textarea",
                              placeholder: "备注长度介于 1 和 80 字符之间",
                              maxlength: "80",
                              autosize: { minRows: 1, maxRows: 5 },
                            },
                            model: {
                              value: _vm.takeOutForm.billRemark,
                              callback: function ($$v) {
                                _vm.$set(_vm.takeOutForm, "billRemark", $$v)
                              },
                              expression: "takeOutForm.billRemark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "选择优惠券类型" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    { staticClass: "couponTable marT10" },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "couponTable",
                          attrs: {
                            data: _vm.takeOutForm.detailItem,
                            border: "",
                            "row-key": "couponCaseId",
                          },
                          on: {
                            "cell-mouse-enter": _vm.cellMouseEnter,
                            "cell-mouse-leave": _vm.cellMouseLeave,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: _vm.tableCellLabel,
                              width: "120",
                              "v-if": !_vm.disabled,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.hoverRow && !_vm.disabled
                                      ? _c("i", {
                                          staticClass:
                                            "el-icon-circle-plus operatePush",
                                          on: {
                                            click: function ($event) {
                                              return _vm.tableRow(
                                                "push",
                                                scope.$index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    scope.row.hoverRow && !_vm.disabled
                                      ? _c("i", {
                                          staticClass:
                                            "el-icon-remove operateDel",
                                          on: {
                                            click: function ($event) {
                                              return _vm.tableRow(
                                                "del",
                                                scope.$index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    !scope.row.hoverRow || _vm.disabled
                                      ? _c("div", [
                                          _vm._v(
                                            " " + _vm._s(scope.$index + 1) + " "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c(
                            "el-table-column",
                            {
                              key: "couponCaseNo",
                              attrs: {
                                label: "优惠券方案编号",
                                width: "220",
                                align: "center",
                                prop: "couponCaseNo",
                                height: "300",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "detailItem." +
                                              scope.$index +
                                              ".couponCaseNo",
                                            rules:
                                              _vm.rules[
                                                "detailItem.couponCaseNo"
                                              ],
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                size: "mini",
                                                disabled: _vm.disabled,
                                              },
                                              nativeOn: {
                                                keyup: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.couponKeyupEnter(
                                                    $event,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.couponCaseNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "couponCaseNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.couponCaseNo",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-more more",
                                                attrs: {
                                                  slot: "suffix",
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleEvent(
                                                      "openCouponDialog",
                                                      { curTable: "detailItem" }
                                                    )
                                                  },
                                                },
                                                slot: "suffix",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("i", { staticStyle: { color: "#ff4949" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" 优惠券方案编号 "),
                              ]),
                            ],
                            2
                          ),
                          _c("el-table-column", {
                            key: "couponCaseName",
                            attrs: {
                              label: "优惠券名称",
                              align: "center",
                              prop: "couponCaseName",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            key: "couponCaseTypeName",
                            attrs: {
                              label: "优惠券类型",
                              align: "center",
                              prop: "couponCaseTypeName",
                            },
                          }),
                          _c("el-table-column", {
                            key: "couponModeName",
                            attrs: {
                              label: "优惠券形式",
                              align: "center",
                              prop: "couponModeName",
                            },
                          }),
                          _c("el-table-column", {
                            key: "couponDesc",
                            attrs: {
                              label: "优惠券内容",
                              align: "center",
                              prop: "couponDesc",
                              "show-overflow-tooltip": true,
                            },
                          }),
                          _c(
                            "el-table-column",
                            {
                              key: "limitQty",
                              attrs: {
                                label: "数量",
                                align: "center",
                                prop: "limitQty",
                                width: "180",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "detailItem." +
                                              scope.$index +
                                              ".limitQty",
                                            rules:
                                              _vm.rules["detailItem.limitQty"],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              size: "mini",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.handleInput(
                                                  scope.$index
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.limitQty,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "limitQty",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.limitQty",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("i", { staticStyle: { color: "#ff4949" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" 数量 (每个会员) "),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用对象规则设置" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent padd10" }, [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "label",
                            attrs: {
                              label: "适用对象",
                              prop: "vipLevelRangeTypeArr",
                            },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                attrs: { disabled: _vm.disabled },
                                on: { change: _vm.handleCheckVip },
                                model: {
                                  value: _vm.takeOutForm.vipLevelRangeTypeArr,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.takeOutForm,
                                      "vipLevelRangeTypeArr",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "takeOutForm.vipLevelRangeTypeArr",
                                },
                              },
                              [
                                _c("el-checkbox", { attrs: { label: "0" } }, [
                                  _vm._v("全部会员"),
                                ]),
                                _c("el-checkbox", { attrs: { label: "1" } }, [
                                  _vm._v("全部会员+排除"),
                                ]),
                                _c("el-checkbox", { attrs: { label: "2" } }, [
                                  _vm._v("指定会员"),
                                ]),
                                _c("el-checkbox", { attrs: { label: "4" } }, [
                                  _vm._v("生日会员"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showVipBD,
                                expression: "showVipBD",
                              },
                            ],
                            staticClass: "selectVipBD marL10 inputWidth",
                            attrs: {
                              clearable: "",
                              size: "mini",
                              filterable: "",
                            },
                            model: {
                              value: _vm.takeOutForm.vipBirthdayType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.takeOutForm,
                                  "vipBirthdayType",
                                  $$v
                                )
                              },
                              expression: "takeOutForm.vipBirthdayType",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.vip_birthday_type,
                            function (dict) {
                              return _c("el-option", {
                                key: dict.value,
                                attrs: { label: dict.label, value: dict.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.vipTable,
                            expression: "vipTable",
                          },
                        ],
                        staticClass: "marL20",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "marB10",
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openVipDialog", {
                                  curTable: "vipLevelItem",
                                })
                              },
                            },
                          },
                          [_vm._v("选择会员")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "marB10",
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openVipLevelDialog", {
                                  curTable: "vipLevelItem",
                                })
                              },
                            },
                          },
                          [_vm._v("选择会员等级")]
                        ),
                        _c("EditTable", { attrs: { options: _vm.vipOptions } }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("selectCoupon", {
        attrs: { OpenCoupon: _vm.openCoupon },
        on: {
          "update:OpenCoupon": function ($event) {
            _vm.openCoupon = $event
          },
          "update:open-coupon": function ($event) {
            _vm.openCoupon = $event
          },
          getCoupons: function ($event) {
            return _vm.getPitchData($event, "coupon")
          },
        },
      }),
      _c("selectVips", {
        attrs: { OpenVipCards: _vm.openVipDia },
        on: {
          "update:OpenVipCards": function ($event) {
            _vm.openVipDia = $event
          },
          "update:open-vip-cards": function ($event) {
            _vm.openVipDia = $event
          },
          getVipS: _vm.getVipList,
        },
      }),
      _c("vipLeve", {
        attrs: { OpenVipLeve: _vm.openVipLeve },
        on: {
          "update:OpenVipLeve": function ($event) {
            _vm.openVipLeve = $event
          },
          "update:open-vip-leve": function ($event) {
            _vm.openVipLeve = $event
          },
          vipLeveList: _vm.getVipLeveList,
        },
      }),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }