<template>
  <!-- 选择门店 -->
  <div class="select-vip">
    <el-dialog
      :visible="OpenVipRank"
      title="会员级别列表"
      :before-close="close"
      v-dialogDrag="true"
      width="70%"
    >
      <div class="app-container">
        <el-row class="main">
          <!--会员数据-->
          <el-col :span="24" :xs="24">
            <!-- 会员数据表格 -->
            <div class="vipRankData">
              <el-table
                v-loading="loading"
                :data="vipRankList"
                @selection-change="handleSelectionChange"
                border
                height="500"
                class="table-vipRank"
                ref="Table"
                row-key="vipLevelId"
              >
                <el-table-column type="selection" width="50" align="center" />
                <el-table-column
                  label="序号"
                  width="60"
                  align="center"
                  type="index"
                />
                <el-table-column
                  label="会员级别编码"
                  align="center"
                  key="vipLevelNo"
                  prop="vipLevelNo"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="会员级别名称"
                  align="center"
                  key="vipLevelName"
                  prop="vipLevelName"
                  :show-overflow-tooltip="true"
                />
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getGoods">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listlevel } from '@/api/vip/base/level.js'
export default {
  name: 'SelectGoods',
  dicts: [
    'App_is_pos_visible',
    'App_del_flag',
    'goods_status',
    'App_is_manage_stock',
    'pricing_mode',
    'goods_type',
    'goods_unit'
  ],
  props: {
    //会员级别开关
    OpenVipRank: {
      type: Boolean,
      default: false
    },
    queryCode: {
      type: String
    }
  },
  data () {
    return {
      //遮罩层
      loading: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 当前页表格数据
      vipRankList: undefined,
      //选中数据
      selectAllData: undefined,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        queryCode: undefined
      }
    }
  },
  watch: {
    // 根据名称筛选分类树
    categoryName (val) {
      this.$refs.tree.filter(val)
    },
    queryCode (val) {
      this.queryParams.queryCode = val
      this.handleQuery()
    },
    OpenVipRank (val) {
      if (!val) {
        this.reset()
      } else {
        this.getList()
      }
    }
  },
  methods: {
    //关闭对话框
    close () {
      this.$emit('update:OpenVipRank', false)
    },
    /** 查询列表 */
    getList () {
      this.loading = true
      listlevel(this.queryParams).then(response => {
        this.vipRankList = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.selectAllData = selection
      this.ids = selection.map(item => item.vipLevelId)
    },
    //清空搜索框
    reset () {
      this.selectAllData = undefined
      this.queryParams = {
        pageNum: 1,
        pageSize: 15
      }
    },
    //选中商品
    getGoods () {
      if (this.ids.length >= 1) this.$emit('getVipRank', this.selectAllData)
      this.close()
    }
  }
}
</script>
