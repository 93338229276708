var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-vip" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            visible: _vm.OpenVipRank,
            title: "会员级别列表",
            "before-close": _vm.close,
            width: "70%",
          },
        },
        [
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c(
                "el-row",
                { staticClass: "main" },
                [
                  _c("el-col", { attrs: { span: 24, xs: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "vipRankData" },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            ref: "Table",
                            staticClass: "table-vipRank",
                            attrs: {
                              data: _vm.vipRankList,
                              border: "",
                              height: "500",
                              "row-key": "vipLevelId",
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "50",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                width: "60",
                                align: "center",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              key: "vipLevelNo",
                              attrs: {
                                label: "会员级别编码",
                                align: "center",
                                prop: "vipLevelNo",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "vipLevelName",
                              attrs: {
                                label: "会员级别名称",
                                align: "center",
                                prop: "vipLevelName",
                                "show-overflow-tooltip": true,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.getGoods } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }