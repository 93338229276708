<template>
  <!-- 选择门店 -->
  <div class="select-shop">
    <el-dialog
      :visible="OpenShop"
      title="门店信息列表"
      :before-close="close"
      v-dialogDrag="true"
      width="70%"
    >
      <div class="app-container">
        <el-row class="main">
          <!--分类数据-->
          <el-col :span="4" :xs="24">
            <div class="head-container">
              <el-input
                v-model="categoryName"
                placeholder="请输入类别名称"
                clearable
                size="small"
                prefix-icon="el-icon-search"
                style="margin-bottom: 20px"
              />
            </div>
            <div class="head-container">
              <!-- 分类树 -->
              <el-scrollbar class="scrollbarTree">
                <el-tree
                  node-key="id"
                  :data="categoryTreeOptions"
                  :expand-on-click-node="false"
                  :filter-node-method="filterNode"
                  ref="tree"
                  default-expand-all
                  highlight-current
                  show-checkbox
                />
              </el-scrollbar>
            </div>
          </el-col>
          <!--类别数据-->
          <el-col :span="19" :xs="24">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-form
                  :model="queryParams"
                  ref="queryForm"
                  size="small"
                  :inline="true"
                  v-show="showSearch"
                  label-width="68px"
                  @submit.native.prevent
                >
                  <el-form-item label="" prop="categoryName">
                    <el-input
                      v-model="queryParams.query"
                      placeholder="门店编号/部门编号/助记码"
                      clearable
                      style="width: 240px"
                      @keyup.enter.native="handleQuery"
                    />
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      icon="el-icon-search"
                      size="mini"
                      @click="handleQuery"
                      >搜索</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
            <!-- 商品数据表格 -->
            <div class="goodsData">
              <el-table
                v-loading="loading"
                :data="shopList"
                @selection-change="handleSelectionChange"
                border
                height="500"
                class="table-goods"
                ref="goodsTable"
                row-key="goodsId"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  align="center"
                  :reserve-selection="true"
                />
                <el-table-column
                  label="排序"
                  width="60"
                  align="center"
                  key="sortNo"
                  prop="sortNo"
                />
                <el-table-column
                  label="门店编号"
                  align="center"
                  key="shopNo"
                  prop="shopNo"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="门店名称"
                  align="center"
                  key="shopName"
                  prop="shopName"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="门店类型"
                  align="center"
                  key="shopTypeName"
                  prop="shopTypeName"
                  show-overflow-tooltip
                />

                <el-table-column
                  label="门店面积"
                  align="center"
                  key="useArea"
                  prop="useArea"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="联系人"
                  align="center"
                  key="linkEmployeeName"
                  prop="linkEmployeeName"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="联系电话"
                  align="center"
                  key="linkEmployeeTel"
                  prop="linkEmployeeTel"
                  show-overflow-tooltip
                  width="140"
                />
                <el-table-column
                  label="门店地址"
                  align="center"
                  key="totalAddress"
                  prop="totalAddress"
                  width="200"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="助记码"
                  align="center"
                  key="zjm"
                  prop="zjm"
                  width="120"
                  show-overflow-tooltip
                />
                <el-table-column label="删除状态" align="center" prop="delFlag">
                  <template slot-scope="scope">
                    <dict-tag
                      :options="dict.type.App_del_flag"
                      :value="scope.row.delFlag"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="备注"
                  align="center"
                  key="remark"
                  prop="remark"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="创建人"
                  align="center"
                  key="createBy"
                  prop="createBy"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="创建时间"
                  align="center"
                  prop="createTime"
                  width="160"
                  sortable
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="修改人"
                  align="center"
                  key="updateBy"
                  prop="updateBy"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="修改时间"
                  align="center"
                  prop="updateTime"
                  width="160"
                  sortable
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.updateTime) }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="tag" @click="showPitchGoods" ref="tag">
                <i class="el-icon-arrow-left" v-show="!openGoods"></i
                ><i class="el-icon-arrow-right" v-show="openGoods"></i>
                <div class="right">
                  <div>已</div>
                  <div>选</div>
                  <span>{{ ids.length }}</span>
                </div>
              </div>
              <div class="pitch-goods" v-show="openGoods">
                <div class="pitch-title">
                  <div class="left">选中的商品</div>
                  <div class="right" @click="delPitchGoods('#')">清空</div>
                </div>
                <el-scrollbar class="scrollbarPitch">
                  <div class="itemList">
                    <div
                      class="item-goods"
                      v-for="item in selectAllData"
                      :key="item.goodsId"
                    >
                      <div class="name">{{ item.goodsName }}</div>
                      <div class="el-icon-close" @click="delPitchGoods(item)"></div>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
            </div>
            <div class="goods-footer">
              <div class="left">
                <span class="select"
                  >已选<span class="num">{{ this.ids.length }}</span
                  >条</span
                >
                <span class="checkAll" v-show="!isCheckAll" @click="selectAll"
                  >选择全部</span
                >
                <span class="checkAll" v-show="isCheckAll" @click="selectAll"
                  >取消选择</span
                >
              </div>
              <!-- 分页 -->
              <pagination
                v-show="total > 0"
                :total="total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="getList"
              />
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getGoods">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDeptTree, listShopInfo } from "@/api/shop/base/shopInfo";
export default {
  name: "SelectGoods",
  dicts: [
    "App_is_pos_visible",
    "App_del_flag",
    "goods_status",
    "App_is_manage_stock",
    "pricing_mode",
    "goods_type",
    "goods_unit",
  ],
  props: {
    //商品信息开关
    OpenShop: {
      type: Boolean,
      default: false,
    },
    query: {
      type: String,
    },
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 当前页商品表格数据
      shopList: undefined,
      //所有商品数据
      goodsAllList: undefined,
      //选中的商品数据
      selectAllData: undefined,
      // 分类树选项
      categoryTreeOptions: undefined,
      // 分类名称
      categoryName: undefined,
      //判断当前是否全选数据
      isCheckAll: false,
      //是否开启选中商品框
      openGoods: false,
      //定位已选图标的位置
      posiRight: "52px",
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        query: undefined,
      },
    };
  },
  watch: {
    // 根据名称筛选分类树
    categoryName(val) {
      this.$refs.tree.filter(val);
    },
    OpenShop(val) {
      if (!val) {
        //关闭时清空
        this.reset();
      } else {
        //打开时调用列表
        this.getList();
        this.getTreeselect();
      }
    },
  },
  methods: {
    //关闭对话框
    close() {
      this.$emit("update:OpenShop", false);
    },
    //下拉树结构数据转换
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    /** 查询商品列表 */
    getList() {
      this.loading = true;
      listShopInfo(this.queryParams).then((response) => {
        this.shopList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },

    /** 查询分类下拉树结构 */
    getTreeselect() {
      getDeptTree().then((response) => {
        this.categoryTreeOptions = response.data;
      });
    },

    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    // 节点单击事件
    handleNodeClick(data) {
      console.log(data);
      this.queryParams.categoryId = data.id;
      this.handleQuery();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectAllData = selection;
      this.ids = selection.map((item) => item.goodsId);
    },
    //选中商品
    getGoods() {
      if (this.ids.length >= 1) this.$emit("getShopData", this.selectAllData);
      this.close();
      this.delPitchGoods("#");
    },
    //清空搜索框
    reset() {
      (this.categoryName = ""),
        (this.queryParams = {
          pageNum: 1,
          pageSize: 15,
          query: undefined,
        });
      this.handleQuery();
    },
    //显示选中的商品
    showPitchGoods() {
      this.span = this.span === 20 ? 16 : this.span;
      this.openGoods = !this.openGoods;
      this.posiRight = this.posiRight === "52px" ? "252px" : "52px";
      this.$refs.tag.style.right = this.posiRight;
    },
    //点击全选所有数据
    async selectAll() {
      this.isCheckAll = !this.isCheckAll;
      if (this.isCheckAll) {
        //判断是否点击全选按钮
        if (this.goodsAllList === undefined) {
          //当全部数据为空时发请求
          //获取全部数据
          const res = await listShopInfo({
            ...this.queryParams,
            pageNum: 1,
            pageSize: this.total,
          });
          this.goodsAllList = res.rows;
        }
        this.goodsAllList.map((row) => {
          if (this.selectAllData) {
            for (let i = 0; i < this.selectAllData.length; i++) {
              if (row.goodsId === this.selectAllData[i].goodsId) return; //当已选中的不需要重复选中
            }
          }
          this.$nextTick(() => {
            this.$refs.goodsTable.toggleRowSelection(row, this.isCheckAll); //全选
          });
        });
      } else {
        this.$nextTick(() => {
          this.$refs.goodsTable.clearSelection();
        });
      }
      this.getList();
    },
    //取消选中的商品
    delPitchGoods(row) {
      if (row === "#") {
        this.$nextTick(() => {
          this.$refs.goodsTable.clearSelection(); //清空选中
        });
      } else {
        this.goodsList.map((item) => {
          if (item.goodsId === row.goodsId) {
            this.$nextTick(() => {
              this.$refs.goodsTable.toggleRowSelection(item); //取消行选中
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-shop {
  //tree区域
  .head-container {
    margin-right: 20px;
  }
  //左边tree滚动条区域
  .scrollbarTree {
    overflow: hidden;
    height: 500px;
  }
  //中间商品表格/选中商品区域
  .goodsData {
    display: flex;
    //表格区域
    .table-goods {
      flex: 4;
      position: relative;
    }
    //右边选中区域
    .pitch-goods {
      flex: 1;
      height: 500px;
      width: 90%;
      border-top: 1px solid #dfe6ec;
      border-right: 1px solid #dfe6ec;
      border-bottom: 1px solid #dfe6ec;
      .pitch-title {
        display: flex;
        justify-content: space-between;
        padding: 0 5px;
        height: 55px;
        line-height: 55px;
        border-bottom: 1px solid #dfe6ec;
        background-color: #f8f8f9;
        .left {
          font-weight: 700;
        }
        .right {
          color: #1890ff;
          cursor: pointer;
        }
      }
      //滚动条
      .scrollbarPitch {
        overflow: hidden;
        height: 445px;
        .itemList {
          .item-goods {
            padding-right: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 56px;
            background-image: linear-gradient(to bottom, #fff, #f5f5f5);
          }
        }
      }
    }
  }

  //表格底部区域
  .goods-footer {
    display: flex;
    justify-content: space-between;
    .left {
      padding: 32px 16px;
      .select {
        margin-right: 5px;
        .num {
          color: #1890ff;
        }
      }
      .checkAll {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }

  //选中的图标
  .tag {
    position: absolute;
    transform: translateY(-50%);
    right: 52px;
    top: 50%;
    display: flex;
    cursor: pointer;
    font-size: 16px;
    width: 50px;
    border: 1px solid #dfe6ec;
    border-radius: 5px;
    align-items: center;
    z-index: 9999;
    background-color: #fff;
    .right {
      display: flex;
      flex-direction: column;
      padding: 0 2px;
      text-align: center;
    }
    span {
      padding: 0 6px;
      text-align: center;
      line-height: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #2386ee;
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>
