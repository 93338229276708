<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <div class="tips" v-if="isPrompt">{{ prompt }}</div>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { takeOutListAPI, getUpdateStatusState, takeOutUpdateStatusAPI } from "@/api/marketing/coupon/takeOut"; //优惠券发放
export default {
  name: "specialprice",
  components: { TablePage },
  data() {
    return {
      isPrompt: false,
      prompt:'优惠券发放中',
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: takeOutListAPI,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "billId",
        labelWidth: "80",
        search: [
          {
            label: "审核时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ auditTimeBegin: a, auditTimeEnd: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "方案编号/方案名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "方案编号" },
              { filter: "promoNames", label: "方案名称" },
            ],
          },
          this.$select({
            key: "bill_status",
            option: { label: "方案状态", seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            // btnType: "dropdown",
            // other: [
            //   {
            //     click: "redoAudit",
            //     label: "反审核",
            //   },
            // ],
            // disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "方案编号",
            type: "link",
            click: "routerLink",
            minWidth: 200,
          },
          {
            prop: "promoName",
            label: "方案名称",
            minWidth: 155,
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 120,
          },
          // {
          //   prop: "billDate",
          //   label: "单据时间",
          //   minWidth: 160,
          // },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    loadingFun (billId) {
      this.isPrompt = true
      getUpdateStatusState(billId).then(res=>{
        console.log(res, 'res')
        if (res.msg == '优惠券发放中，请耐心等待...') {
          setTimeout(()=>{
            this.loadingFun(billId)
          }, 60000)
        } else {
          // this.$message.success(res.msg)
          this.$alert('发放优惠券成功', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              // this.$message({ //   type: 'info',//   message: `action: ${ action }` // });
            }
          });
          this.isPrompt = false
        }
      }).catch(err=> {
        console.log(err, 'err')
        this.isPrompt = false
      })
    },
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "takeOutDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: "takeOutDetail",
            });
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("审核", selectData);
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData);
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() {},
    handleBillStatus(command, selectData) {
      let name = "";
      let billStatus = "";
      if (command === "审核") {
        name = "审核";
        billStatus = "2";
      } else if (command === "反审核") {
        name = "反审核";
        billStatus = "0";
      } else if (command === "删除") {
        name = "删除";
        billStatus = "3";
      }
      const billId = selectData.map((i) => i.billId);
      const billNo = selectData.map((i) => i.billNo);
      if (name == '审核' && billId.length > 1) {
        return this.$message.error('只可以选择一条审核数据')
      }
      const $this = this;
      this.$modal.confirm(`是否确认${name}方案编号为"` + billNo + '"?').then(function () {
        takeOutUpdateStatusAPI({ billIds: [...billId], billStatus: billStatus }).then(
          (res) => {
            $this.$nextTick(() => {
              $this.options.check = [];
            });
            if (name == '审核') {
              console.log(name, 'name');
              $this.loadingFun(billId[0])
            }
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
            $this.$modal.msgSuccess(`${name}成功`);
          }
        );
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tips {
  position: fixed;
  width: 300px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: -100px;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 200px;
  z-index: 300;
  color: #fff;
  border-radius: 5px;
}
</style>
