var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.packageForm,
            rules: _vm.rules,
            "label-width": "96px",
          },
        },
        [
          [
            _c("topOperatingButton", {
              attrs: {
                isSubmitAddBtn: false,
                isAuditBillBtn: false,
                id: "topOperatingButton",
              },
              on: {
                submitForm: function ($event) {
                  return _vm.submitForm(false)
                },
                getQuit: _vm.getQuit,
              },
            }),
          ],
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "优惠券券包" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent x-start" },
                  [
                    _c("el-image", {
                      staticClass: "marR20",
                      staticStyle: { width: "375px" },
                      attrs: {
                        src: require("@/assets/images/preferential.png"),
                      },
                    }),
                    _c(
                      "div",
                      { staticStyle: { width: "calc(100% - 395px)" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "label",
                            attrs: { label: "券包名称", prop: "packageName" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidthM",
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.packageForm.packageName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.packageForm, "packageName", $$v)
                                },
                                expression: "packageForm.packageName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-form-item", {
                          staticClass: "label",
                          attrs: { label: "券包内容" },
                        }),
                        _c(
                          "div",
                          { staticClass: "couponTable marB20" },
                          [
                            _c(
                              "el-table",
                              {
                                ref: "couponTable",
                                attrs: {
                                  data: _vm.packageForm
                                    .marketingCouponPackageDetails,
                                  border: "",
                                  "max-height": "400",
                                  height: "400",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "序号",
                                    type: "index",
                                    width: "50",
                                    align: "center",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "操作",
                                    width: "120",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus operatePush",
                                            on: {
                                              click: function ($event) {
                                                return _vm.tableRow(
                                                  _vm.packageForm
                                                    .marketingCouponPackageDetails,
                                                  "push",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          }),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-remove operateDel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.tableRow(
                                                  _vm.packageForm
                                                    .marketingCouponPackageDetails,
                                                  "del",
                                                  scope.$index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                                _c(
                                  "el-table-column",
                                  {
                                    key: "couponCaseNo",
                                    attrs: {
                                      label: "优惠券方案编号",
                                      width: "220",
                                      align: "center",
                                      prop: "couponCaseNo",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "marketingCouponPackageDetails." +
                                                    scope.$index +
                                                    ".couponCaseNo",
                                                  rules:
                                                    _vm.rules[
                                                      "marketingCouponPackageDetails.couponCaseNo"
                                                    ],
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    attrs: { size: "mini" },
                                                    nativeOn: {
                                                      keyup: function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.couponKeyupEnter(
                                                          $event,
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.couponCaseNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "couponCaseNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.couponCaseNo",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-more more",
                                                      attrs: { slot: "suffix" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.openCoupon = true
                                                        },
                                                      },
                                                      slot: "suffix",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c(
                                        "i",
                                        { staticStyle: { color: "#ff4949" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" 优惠券方案编号 "),
                                    ]),
                                  ],
                                  2
                                ),
                                _c("el-table-column", {
                                  key: "couponCaseName",
                                  attrs: {
                                    label: "优惠券名称",
                                    align: "center",
                                    prop: "couponCaseName",
                                    "show-overflow-tooltip": "",
                                    width: "140",
                                  },
                                }),
                                _c("el-table-column", {
                                  key: "couponCaseTypeName",
                                  attrs: {
                                    label: "优惠券类型",
                                    align: "center",
                                    prop: "couponCaseTypeName",
                                    width: "100",
                                  },
                                }),
                                _c("el-table-column", {
                                  key: "couponModeName",
                                  attrs: {
                                    label: "优惠券形式",
                                    align: "center",
                                    prop: "couponModeName",
                                    width: "100",
                                  },
                                }),
                                _c("el-table-column", {
                                  key: "couponDesc",
                                  attrs: {
                                    label: "优惠券内容",
                                    align: "center",
                                    prop: "couponDesc",
                                    "show-overflow-tooltip": true,
                                    "min-width": "100",
                                  },
                                }),
                                _c("el-table-column", {
                                  key: "expirationDate",
                                  attrs: {
                                    label: "有效期时间",
                                    align: "center",
                                    prop: "expirationDate",
                                    "show-overflow-tooltip": true,
                                    width: "300",
                                  },
                                }),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "赠券数",
                                      align: "center",
                                      prop: "couponQty",
                                      width: "145",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "marketingCouponPackageDetails." +
                                                    scope.$index +
                                                    ".couponQty",
                                                  rules:
                                                    _vm.rules[
                                                      "marketingCouponPackageDetails.couponQty"
                                                    ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    size: "mini",
                                                    label: "赠券数",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.handleInput(
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row.couponQty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "couponQty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.couponQty",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c(
                                        "i",
                                        { staticStyle: { color: "#ff4949" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" 赠券数 "),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "领取后多少天生效",
                                      align: "center",
                                      prop: "couponQty",
                                      width: "145",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "marketingCouponPackageDetails." +
                                                    scope.$index +
                                                    ".drawDay",
                                                  rules:
                                                    _vm.rules[
                                                      "marketingCouponPackageDetails.drawDay"
                                                    ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value: scope.row.drawDay,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "drawDay",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.drawDay",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c(
                                        "i",
                                        { staticStyle: { color: "#ff4949" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" 领取后多少天生效 "),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "有效期天数",
                                      align: "center",
                                      prop: "couponQty",
                                      width: "145",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "marketingCouponPackageDetails." +
                                                    scope.$index +
                                                    ".validDay",
                                                  rules:
                                                    _vm.rules[
                                                      "marketingCouponPackageDetails.validDay"
                                                    ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value: scope.row.validDay,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "validDay",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.validDay",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c(
                                        "i",
                                        { staticStyle: { color: "#ff4949" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" 有效期天数 "),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "券包售价", prop: "packagePrice" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth marR10",
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.packageForm.packagePrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.packageForm, "packagePrice", $$v)
                                },
                                expression: "packageForm.packagePrice",
                              },
                            }),
                            _c("span", { staticClass: "fontS14" }, [
                              _vm._v("元"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "发放上限",
                              prop: "packageMaxCount",
                            },
                          },
                          [
                            _c("el-input-number", {
                              staticClass: "inputWidth marR10",
                              attrs: {
                                size: "mini",
                                min: 1,
                                label: "礼包发放上限",
                              },
                              model: {
                                value: _vm.packageForm.packageMaxCount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.packageForm,
                                    "packageMaxCount",
                                    $$v
                                  )
                                },
                                expression: "packageForm.packageMaxCount",
                              },
                            }),
                            _c("span", { staticClass: "fontS14" }, [
                              _vm._v("份"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "失效条件",
                              prop: "packageLapseType",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.packageForm.packageLapseType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.packageForm,
                                      "packageLapseType",
                                      $$v
                                    )
                                  },
                                  expression: "packageForm.packageLapseType",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v(
                                    "任意一张优惠券库存不足或失效时礼包失效"
                                  ),
                                ]),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("所有优惠券库存不足或失效时礼包失效"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "分享设置",
                              prop: "isCanPackageShare",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              model: {
                                value: _vm.isCanPackageShare,
                                callback: function ($$v) {
                                  _vm.isCanPackageShare = $$v
                                },
                                expression: "isCanPackageShare",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        2
      ),
      _c("selectCoupon", {
        attrs: { OpenCoupon: _vm.openCoupon },
        on: {
          "update:OpenCoupon": function ($event) {
            _vm.openCoupon = $event
          },
          "update:open-coupon": function ($event) {
            _vm.openCoupon = $event
          },
          getCoupons: function ($event) {
            return _vm.getPitchData($event, "coupon")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }