<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";

import { recordListAPI, listSummary } from "@/api/marketing/coupon/drawAndVerifyRecord"; //优惠券账本记录
export default {
  name: "specialprice",
  components: { TablePage },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        defaultBody: {
          couponStatuses: [4],
        },
        exportOption: {
          show: true,
          exportApi: 'exportBookListExport',
          exportName: '优惠券核销记录',
          timeout: 60*5*1000
        },
        getListApi: recordListAPI,
        getSummaryApi: listSummary,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "billId",
        search: [
          {
            label: "核销时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ usedCheckTimeBegin: a, usedCheckTimeEnd: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "方案编号/方案名称/优惠券号/会员卡号/会员名称/手机号",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "couponCaseNos", label: "方案编号" },
              { filter: "couponCaseNames", label: "方案名称" },
              { filter: "couponNos", label: "优惠券号" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
            ],
          },
          {
            label: "领用时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ drawTimeBegin: a, drawTimeEnd: b }),
            seniorSearch: true,
          },
          this.$select({ key: "coupon_take_out_type", option: { labelWidth:'100px', seniorSearch: true, option: { multiple: true }}}),
          this.$select({ key: "coupon_case_type", option: { labelWidth:'100px', seniorSearch: true, option: { multiple: true }} }),
          this.$select({ key: "listVerification", option: { labelWidth:'100px', seniorSearch: true, option: { multiple: true }} }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          // {
          //   click: "export",
          //   right: true,
          //   label: "导出",
          //   icon: "el-icon-upload2",
          //   type: "",
          // },
          // {
          //   click: "refresh",
          //   right: true,
          //   label: "刷新",
          //   icon: "el-icon-refresh",
          //   type: "",
          // },
        ],
        columns: [
          {
            prop: "couponCaseNo",
            label: "优惠券方案编号",
            minWidth: 200,
          },
          {
            prop: "couponCaseName",
            label: "优惠券方案名称",
            minWidth: 155,
          },
          {
            prop: "couponNo",
            label: "优惠券号",
            minWidth: 120,
          },
          {
            prop: "couponDesc",
            label: "优惠券内容",
            minWidth: 160,
          },
          {
            prop: "usedBillNo",
            label: "销售小票号",
            minWidth: 160,
          },
          {
            prop: "usedCheckShopName",
            label: "核销门店",
            minWidth: 160,
          },
          {
            prop: "usedCheckCashierName",
            label: "收银员",
            minWidth: 160,
          },
          {
            prop: "couponCaseTypeName",
            label: "优惠券类型",
            minWidth: 160,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 160,
          },
          {
            prop: "vipNo",
            label: "会员卡号",
            minWidth: 160,
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 160,
          },
          {
            prop: "usedValue",
            label: "已核销金额",
            minWidth: 160,
          },
          {
            prop: "usedCheckTime",
            label: "核销时间",
            minWidth: 160,
          },
          {
            prop: "takeOutTime",
            label: "优惠券领用时间",
            minWidth: 160,
          },
        ],
        summary: ['usedValue'],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "export":
          console.log("导出没有这个功能");
          // this.handleBillStatus("导出", selectData);
          break;
        default:
          break;
      }
    },
    seniorSearch() {},
    handleBillStatus(command, selectData) {
      let name = "";
      if (command === "导出") {
        name = "导出";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
