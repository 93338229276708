<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { CouponList, isStopCoupon, deleteCoupon, copyCoupon } from '@/api/marketing/coupon/case' //接口api
import {
  packageListAPI,
  packageDeleteAPI,
  packageIsStopAPI,
  packageCopyAPI
} from '@/api/marketing/coupon/package' //优惠券包
export default {
  name: "specialprice",
  components: { TablePage },
  dicts: [
    'coupon_mode', //优惠券形式
    'coupon_case_type' //优惠券类型
  ],
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: packageListAPI,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "couponCaseId",
        search: [
          this.$select({ key: "isStopNum", option: { option: { multiple: true } }}),
          {
            label: '券包名称',
            type: "input",
            tip:"请输入券包名称",
            model: "",
            filter: "query",
          },
          // , option: { multiple: true }
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          // {
          //   type: "button",
          //   tip: "高级查询",
          //   btnType: "primary",
          //   click: "seniorSearch",
          // },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "enable",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoEnable",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "packageName",
            label: "券包名称",
            type: "link",
            click: "routerLink",
            minWidth: 200,
          },
          {
            prop: "isStop",
            label: "方案启用",
            minWidth: 100,
            formatter: (v) => v == 1? '启用' : '禁用'
          },
          {
            prop: "packagePrice",
            label: "券包售价",
            minWidth: 155,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "routerLink":
          {
            const packageId = row.packageId
            this.$router.push({
              name: 'packageDetail',
              query: {
                packageId: packageId,
                type: 'Update'
              }
            })
          }
          break;
        case "add":
          {
            this.$router.push({
              name: 'packageDetail'
            })
          }
          break;
        case "enable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("启用", selectData);
          }
          break;
        case "redoEnable":
          {
            if (!selectData.length) return;
            this.handleBillStatus("禁用", selectData);
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            this.handleBillStatus("复制", selectData);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData);
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() {},
    handleBillStatus(command, selectData) {
      let name = "";
      let isStop = "";
      if (command === "复制") {
        name = "复制";
      } else if (command === "启用") {
        name = "启用";
        isStop = 1;
      } else if (command === "禁用") {
        name = "禁用";
        isStop = 0;
      } else if (command === "删除") {
        name = "删除";
      } 
      const packageId = selectData.map((i) => i.packageId);
      const packageName = selectData.map((i) => i.packageName);
      // return
      const $this = this;
      this.$modal
        .confirm(`是否确认${name}券包名称"` + packageName + '"?')
        .then(function () {
          if (['启用', '禁用'].includes(name)) {
            packageIsStopAPI({packageIds: [...packageId],isStop: isStop} ).then(res => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
              if (isStop) {
                $this.$modal.msgSuccess('优惠券券包启用成功')
              } else {
                $this.$modal.msgSuccess('优惠券券包禁用成功')
              }
            })
          } else{
            let url = name == '复制'? packageCopyAPI : packageDeleteAPI
            url([...packageId] ).then(res => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
              $this.$modal.msgSuccess(`${name}成功`)
            })
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
