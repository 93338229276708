var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "discount-scheme" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [_c("span", [_vm._v("选择代金券类型")])]
        ),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "discount-coupon" }, [
            _c(
              "div",
              { staticClass: "list" },
              _vm._l(_vm.specialList.special, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "item",
                    on: {
                      click: function ($event) {
                        return _vm.openDiscountDetail(item.id)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("【" + _vm._s(item.title) + "】"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "middle",
                        class: {
                          darkBlue: item.id === "0",
                          purple: item.id === "1",
                          cambridgeBlue: item.id === "2",
                        },
                      },
                      [
                        _c("div", { staticClass: "left" }, [
                          _c("div", { staticClass: "content" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#333333",
                                },
                              },
                              [_vm._v(_vm._s(item.content))]
                            ),
                            _c("div", { staticClass: "tag" }, [
                              _vm._v(_vm._s(item.tag)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }