<template>
  <!-- 优惠券方案 -->
  <div class="discount-scheme">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>选择代金券类型</span>
      </div>
      <div class="main">
        <!-- 优惠券区域 -->
        <div class="discount-coupon">
          <div class="list">
            <div
              class="item"
              v-for="item in specialList.special"
              :key="item.id"
              @click="openDiscountDetail(item.id)"
            >
              <div class="title">【{{ item.title }}】</div>
              <div
                class="middle"
                :class="{
                  darkBlue: item.id === '0',
                  purple: item.id === '1',
                  cambridgeBlue: item.id === '2'
                }"
              >
                <div class="left">
                  <div class="content">
                    <div style="font-size: 14px;color: #333333;">{{ item.content }}</div>
                    <div class="tag">{{ item.tag }}</div>
                  </div>
                </div>
                <div class="right">
                  <div class="text">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'DiscountScheme',
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter (to, from, next) {
    console.log('进来了')
    next()
  },
  data () {
    return {
      //促销方案
      specialList: {
        special: [
          {
            id: '0',
            title: '代金券',
            content: '买家可凭券享抵扣现金服务',
            tag: '如: 无门槛减10元,满100元减20元'
          },
          {
            id: '1',
            title: '折扣券',
            content: '买家可凭券享消费折扣服务',
            tag: '如: 无门槛打9折,满100元打9折'
          },
          {
            id: '2',
            title: '抵扣券',
            content: '买家可凭券享兑换指定商品服务',
            tag: '如: 3元啤酒抵扣券,10元面包抵扣券'
          }
        ]
      }
    }
  },
  methods: {
    //打开优惠券方案明细
    openDiscountDetail (id) {
      if (id === '0') {
        //代金券
        this.$router.push({
          name: 'Voucher',
          query: {
            type: 'add'
          }
        })
      } else if (id === '1') {
        //折扣券
        this.$router.push({
          name: 'DiscountCoupon',
          query: {
            type: 'add'
          }
        })
      } else {
        //抵扣券
        this.$router.push({
          name: 'DeductionCoupon',
          query: {
            type: 'add'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.discount-scheme {
  padding: 10px;
  background-color: #f3f3f3;
  // height: 100%;
  height: calc(100vh - 90px);
  overflow-y: auto;
  ::v-deep .el-card {
    height: 100%;
  }
  .box-card {
    .main {
      padding-top: 30px;
      padding-bottom: 60px;
      height: 735px;

      .discount-coupon {
        margin: 15px 0;
      }
      .list {
        width: 100%;
        display: flex;
        margin-top: 15px;
        margin-bottom: 50px;
        flex-wrap: wrap;

        .item {
          width: 23%;
          margin: 0 20px;
          //标题
          .title {
            text-align: left;
            font-weight: 700;
            margin-bottom: 15px;
          }
          .middle {
            display: flex;
            height: 128px;
            box-shadow: 5px 10px 5px #ededf1;

            &.darkBlue {
              background-color: #338df7;
            }
            &.purple {
              background-color: #784efb;
            }
            &.cambridgeBlue {
              background-color: #01abf6;
            }
            .left {
              margin: 15px;
              margin-right: 0;
              width: 261px;
              padding: 15px 0;
              background-image: url('~@/assets/images/SpecialScheme.png');
              .content {
                div {
                  font-size: 12px;
                  padding: 10px 10px;
                  font-weight: 700;
                  &.tag {
                    color: #ccc;
                  }
                }
              }
            }
            .right {
              width: 95px;
              font-size: 25px;
              font-weight: 700;
              color: #fff;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              text-align-last: justify;
            }
          }
        }
      }
    }
  }
}
</style>
